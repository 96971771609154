import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";
import { Link } from "react-router-dom";
import "./Dashlogout.css"; // Import the CSS file

const DashLogout = () => {
  const { logout } = useAuth();
  const [confirmLogout, setConfirmLogout] = useState(false);
  const [navigateToSignin, setNavigateToSignin] = useState(false);

  useEffect(() => {
    if (confirmLogout) {
      logout();
      setNavigateToSignin(true);
    }
  }, [confirmLogout, logout]);

  if (navigateToSignin) {
    return <Navigate to="/signin" />;
  }

  return (
    <div className="logout-popup-overlay">
      <div className="logout-popup">
        <p className="logout-message">Do you want to logout?</p>
        <div className="logout-buttons">
          <Link
            className="logout-button confirm"
            onClick={() => {
              logout();
              setConfirmLogout(true);
            }}
            to="/signin"
          >
            Yes
          </Link>
          <Link
            className="logout-button cancel"
            onClick={() => {
              setNavigateToSignin(false);
            }}
            to="/dashboard/operate"
          >
            No
          </Link>
        </div>
      </div>
    </div>
  );
};

export default DashLogout;