import { createContext, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { PublicClientApplication } from '@azure/msal-browser';

import axios from "axios";

const AuthContext = createContext(null);

// mal-config variables
const msalConfig = {
  auth: {
      // clientId: "443ff32f-c7bf-4035-ae77-1a4974c2fd74",
      clientId: "43a036d2-c1b4-44b5-a621-d8c59bb354fe",
      // authority: `https://login.microsoftonline.com/0b837c6d-6e0e-4ecb-bf34-d2f9b7a479f1`,
      authority: `https://login.microsoftonline.com/2d6b0cf3-57fa-4619-abf9-d13e1ef2352a`,
      redirectUri: `${window.location.origin}/dashboard/operate`,
  },
};

const msalInstance = new PublicClientApplication(msalConfig);

export const AuthProvider = ({ children }) => {

  const [userdetails, setUserdetails] = useState({
    isAuthenticated: localStorage.getItem("crobot") || false,
  });
  const [currentUser, setCurrentUser] = useState(null);
  const [roboList, setRoboList] = useState([]);
  const [errorContext, setErrorContext] = useState(null);
  const[msUserId, setMsUserId] = useState(null);
  const [msAccessToken, setMSAccessToken] = useState(null);
  const [msAcsToken, setMsAcsToken] = useState(null);
  const[msAcsUserId, setMsAcsUserId] = useState(null);

  const navigate = useNavigate();

  const signUp = async (username, email, password, confirmPassword) => {
    if (password === confirmPassword) {
      try {
        await axios
          .post(`${process.env.REACT_APP_API}/api/users/`, {
            Username: username,
            email: email,
            password: password,
          })
          .then((data) => {
            const token = data.token;
          });
        setErrorContext(null);
      } catch (error) {
        setErrorContext(error);
      }
    }
  };

  const logIn = async (email, password) => {
    try {
      await axios
        .post(`${process.env.REACT_APP_API}/api/users/login`, {
          "email": email,
          "password": password,
        })
        .then((data) => {
          const response = data.data;
          if(response.success != 0) {
            const userObject = {
              id: response.details.id,
              type: response.details.type,
              username: response.details.username,
              email: response.details.email,
              type: response.details.type,
              subscriptionStatus: response.details.subscriptionStatus,
              createdAt: response.details.createdAt,
              name: response.details.name,
              token: response.token
            }
            setCurrentUser(userObject);
            setErrorContext(null);
            sessionStorage.setItem("crobot", JSON.stringify(userObject));
          }
          else {
            setErrorContext(response.data);
            setCurrentUser(null);
          }
        });
    } catch (error) {
      setCurrentUser(null);
      setErrorContext(error);
    }
  };

  const logout = () => {
    sessionStorage.clear();
    setCurrentUser(null);
    setErrorContext(null);
  };

  const getUserDetails = async (id, token) => {
    try {
      await axios
        .get(`${process.env.REACT_APP_API}/api/users/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        })
        .then((response) => {
          const data = response.data
          if(data.success == 1) {
            const userObject = {
              id: data.details.id,
              type: data.details.type,
              username: data.details.username,
              email: data.details.email,
              subscriptionStatus: data.details.subscriptionStatus,
              createdAt: data.details.createdAt,
              name: data.details.name,
              address: data.details.address,
              mobileNumber: data.details.mobileNumber,
              token: token
            }
            setCurrentUser(userObject);
            setErrorContext(null);
          }
          else {
            setErrorContext(response.message)
            setCurrentUser(null)
          }
          // const retobj = {
          //   username: data.data.username,
          //   email: data.data.email,
          //   id: id
          // }
          // return retobj
        })
    } catch (error) {
      setErrorContext(error)
    }
  }

  const getLinkedRobots = async (id) => {
    try {
      await axios
      .get(`${process.env.REACT_APP_API}/api/robo/linked/${id}`, {
        headers: { 'Authorization': `Bearer ${currentUser.token}` }
      })
        .then((response) => {
          const data = response.data
          if(data.success === 1) {
            const roboArray = data.robos
            setRoboList(roboArray)
            setErrorContext(null)
          }
        })
    } catch (error) {
      setErrorContext(error)
    }
  }

  const updateProfile = async (data, token) => {
    try {
      await axios
        .patch(`${process.env.REACT_APP_API}/api/users/updateUser`, data, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        })
        .then((response) => {
          const data = response.data;
          if(data.success == 1) {
            setErrorContext(null);
            return data;
          }
          else {
            setErrorContext(data.message);
          }
        })
    } catch (error) {
      setErrorContext(error);
    }
  }

  const putContactQuery = async (data) => {
    try {
      await axios
        .post(`${process.env.REACT_APP_API}/api/contact/`, data)
        .then((res) => {
          const data = res.data;
          if(data.success === 1) {
            setErrorContext(null);
            return data.message;
          }
          else {
            setErrorContext(data.message);
          }
        })
    } catch (error) {
      setErrorContext(error);
    }
  }

  // ms teams login
  const msLogin = async()=>{
    const loginRequest = {
        scopes: ['User.ReadBasic.All', 'OnlineMeetings.ReadWrite','Calendars.Read'],
    };
    try {
        await msalInstance.initialize();
        const response = await msalInstance.loginPopup(loginRequest);
        // Set the active account
        msalInstance.setActiveAccount(response.account);
        await msalInstance.acquireTokenSilent(loginRequest).then(tokenResponse =>{
          setMSAccessToken(tokenResponse.accessToken); 
          const requestBody = {
            aadToken: tokenResponse.accessToken
          }
          axios.post(`${process.env.REACT_APP_API}/api/graph/acstoken`, requestBody,{
              headers: {'Authorization': `Bearer ${currentUser.token}`}
            }).then((res) => {
                const data = res.data
                if(data.success === 1){
                  setMsAcsUserId(data.acsUserId);
                  setMsAcsToken(data.acsToken);
                  setMsUserId(data.userId)
                }
                //set acs token and acs user id here
             })
        });
    } catch (error) {
        console.error('Login error:', error);
    }
}

  // useEffect(() => {
  //   setCurrlocalStorage.getItem("crobot");
  // }, [currentUser]);

  return (
    // <AuthContext.Provider
    //   value={{ userdetails, currentUser, setUserdetails, logIn, logout, signUp, refreshAcc }}
    // >
    // <AuthContext.Provider value={{ logIn, signUp, currentUser, logout, getUserDetails, getLinkedRobots, updateProfile, putContactQuery, roboList, errorContext , msUserId,msAccessToken,msLogin, msAcsToken,msAcsUserId}}>
    //   {children}
    // </AuthContext.Provider>
    <AuthContext.Provider value={{ logIn, signUp, currentUser, logout, getLinkedRobots, updateProfile, putContactQuery, roboList, errorContext , msUserId,msAccessToken,msLogin, msAcsToken,msAcsUserId}}>
    {children}
  </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
