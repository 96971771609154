import { useState } from "react";
import {
  TextField,
  Button,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import "./SignIn.css";

const SignUp = ({ setSignup, setSignin }) => {
  const [formDetails, setFormDetails] = useState({
    email: "",
    otp: "",
    newPassword: "",
    confirmPassword: "",
  });
  const [otpVerified, setOtpVerified] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");
  const [otpSent, setOtpSent] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormDetails((prev) => ({ ...prev, [name]: value }));
  };

  const togglePasswordVisibility = () => setShowPassword((prev) => !prev);
  const toggleConfirmPasswordVisibility = () =>
    setShowConfirmPassword((prev) => !prev);

  const handleSendOtp = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API}/api/users/sendOtp`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ email: formDetails.email }),
      });
      const result = await response.json();

      if (result.success) {
        setOtpSent(true);
        setMessage("OTP sent successfully! Check your email.");
        setError("");
      } else {
        setError("Failed to send OTP. Try again.");
      }
    } catch (err) {
      setError("An error occurred. Please try again.");
    }
  };

  const handleVerifyOtp = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API}/api/users/verifyOtp`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          email: formDetails.email,
          otp: formDetails.otp,
        }),
      });
      const result = await response.json();

      if (result.success) {
        setOtpVerified(true);
        setMessage("OTP verified successfully!");
        setError("");
      } else {
        setError("Invalid OTP. Please try again.");
      }
    } catch (err) {
      setError("An error occurred. Please try again.");
    }
  };

  const handleResetPassword = async () => {
    try {
      if (formDetails.newPassword !== formDetails.confirmPassword) {
        setError("Passwords do not match.");
        return;
      }

      const response = await fetch(`${process.env.REACT_APP_API}/api/users/resetPassword`, {
        method: "PATCH",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          email: formDetails.email,
          otp:formDetails.otp,
          password: formDetails.newPassword,
        }),
      });
      const result = await response.json();

      if (result.success) {
        setMessage("Password reset successfully!");
        setError("");
        setSignin(true);
        setSignup(false);
      } else {
        setError("Failed to reset password. Try again.");
      }
    } catch (err) {
      setError("An error occurred. Please try again.");
    }
  };

  return (
    <div className="signin_background">
      <div className="signin_container">
        <div className="form-wrapper">
          <div className="log-sign-heading">
            {otpVerified ? "Reset Password" : "Forgot Password"}
          </div>
          {!otpVerified ? (
            <>
              <TextField
                required
                label="Email ID"
                margin="normal"
                style={{ width: "90%" }}
                name="email"
                value={formDetails.email}
                onChange={handleInputChange}
              />
              {otpSent && (
                <>
                  <TextField
                    required
                    label="Enter OTP"
                    margin="normal"
                    style={{ width: "90%" }}
                    name="otp"
                    value={formDetails.otp}
                    onChange={handleInputChange}
                  />
                </>
              )}
              {message && <p style={{ color: "green" }}>{message}</p>}
              {error && <p style={{ color: "red" }}>{error}</p>}
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-evenly",
                }}
              >
                {otpSent && (
                  <Button onClick={handleVerifyOtp} id="verify-otp-button" className="form-button">
                    Verify
                  </Button>
                )}
                <Button onClick={handleSendOtp} id="send-otp-button" className="form-button">
                  Send OTP
                </Button>
              </div>
            </>
          ) : (
            <>
              <FormControl style={{ width: "90%" }}>
                <InputLabel htmlFor="new-password">New Password</InputLabel>
                <OutlinedInput
                  id="new-password"
                  type={showPassword ? "text" : "password"}
                  name="newPassword"
                  value={formDetails.newPassword}
                  onChange={handleInputChange}
                  endAdornment={
                    <InputAdornment
                      position="end"
                      sx={{
                        padding: 0,
                        margin: 0,
                        minWidth: 0,
                        ".MuiIconButton-root": {
                          padding: "4px",
                          fontSize: "1rem",
                          backgroundColor: "transparent",
                          "&:hover": {
                            backgroundColor: "transparent",
                          },
                          "&:active": {
                            backgroundColor: "transparent",
                          },
                        },
                      }}
                    >
                      <IconButton onClick={togglePasswordVisibility} edge="end">
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>
              <FormControl style={{ width: "90%" }}>
                <InputLabel htmlFor="confirm-password">
                  Confirm Password
                </InputLabel>
                <OutlinedInput
                  id="confirm-password"
                  type={showConfirmPassword ? "text" : "password"}
                  name="confirmPassword"
                  value={formDetails.confirmPassword}
                  onChange={handleInputChange}
                  endAdornment={
                    <InputAdornment
                      position="end"
                      sx={{
                        padding: 0,
                        margin: 0,
                        minWidth: 0,
                        ".MuiIconButton-root": {
                          padding: "4px",
                          fontSize: "1rem",
                          backgroundColor: "transparent",
                          "&:hover": {
                            backgroundColor: "transparent",
                          },
                          "&:active": {
                            backgroundColor: "transparent",
                          },
                        },
                      }}
                    >
                      <IconButton
                        onClick={toggleConfirmPasswordVisibility}
                        edge="end"
                      >
                        {showConfirmPassword ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>
              {error && <p style={{ color: "red" }}>{error}</p>}
              {/* {message && <p style={{ color: "green" }}>{message}</p>} */}
              <Button className="form-button" onClick={handleResetPassword} id="reset-password-button">
                Reset Password
              </Button>
            </>
          )}
          <div
            id="signupbanner"
            style={{ fontWeight: "300", cursor: "pointer", color: "black" }}
          >
            Already have an account?{" "}
            <span
              style={{
                fontWeight: "500",
                cursor: "pointer",
                color: "black",
              }}
              onClick={() => {
                setSignup((prev) => !prev);
                setSignin((prev) => !prev);
              }}
            >
              Login here!
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUp;
