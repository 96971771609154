import React, { useState, useEffect } from "react";
import "../DashboardComp/Utils.css";

const Battery = ({ ws }) => {
  const [chargeLevel, setChargeLevel] = useState(null);

  // Determine the color based on charge level
  const getBatteryColor = () => {
    if (chargeLevel > 50) return "rgb(124,223,52)";
    if (chargeLevel > 25) return "orange";
    return "red";
  };

  // Setting up WebSocket listeners in useEffect
  useEffect(() => {
    if (ws && ws.data === "battery") {
      setChargeLevel(ws.percent);
    }
  }, [ws]); // Dependency array ensures this runs when `ws` changes

  return (
    <div className="battery-container">
      <div className="battery">
        {chargeLevel !== null ? (
          <>
            <div
              className="battery-fill"
              style={{
                width: `${chargeLevel}%`,
                backgroundColor: getBatteryColor(),
              }}
            ></div>
            <p className="battery-percentage">{`${chargeLevel}%`}</p>
          </>
        ) : (
          <div className="no-battery">
          </div>
        )}
        <div className="battery-cap"></div>
      </div>
    </div>
  );
};

export default Battery;
