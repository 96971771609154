import React, { useState, useEffect } from "react";
import "./Utils.css";

const IndicatorButton = ({ ws }) => {
  const [obstacle, setObstacle] = useState(true);

  // Function to determine the background color
  const getBackgroundColor = () => {
    return obstacle ? "red" : "green";
  };

  // Function to determine the box-shadow
  const getBoxShadow = () => {
    return obstacle
      ? "0 0 5px rgba(255, 0, 0, 0.5), 0 0 10px rgba(255, 0, 0, 0.3), 0 0 20px rgba(255, 0, 0, 0.2)"
      : "0 0 5px rgba(0, 255, 0, 0.5), 0 0 10px rgba(0, 255, 0, 0.3), 0 0 20px rgba(0, 255, 0, 0.2)";
  };

  useEffect(() => {
    if (ws && ws.data === "obstacle") {
      if(ws.obstacle === "true"){
        setObstacle(true);
      }else{
      setObstacle(false);
      }
    }
  }, [ws]); // Dependency array ensures the effect runs when `ws` changes

  return (
    <div
      className="indicator-button"
      style={{
        width: "15px",
        height: "15px",
        borderRadius: "50%",
        backgroundColor: getBackgroundColor(),
        boxShadow: getBoxShadow(),
      }}
    ></div>
  );
};

export default IndicatorButton;
